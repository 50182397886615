import React, { useRef, useState } from "react";
import "./Header.css";
import { IconMenu, IconPlay, LogoRefabric } from "icons";
import Button from "components/Button/Button";
import clsx from "clsx";
import AvatarMenu from "components/AvatarMenu/AvatarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, toggleAnnouncementModal } from "store/commonSlice";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import { useIsMobile } from "hooks/useIsMobile";
import { AnalyticsEvent } from "utils";
import { PATHS } from "../../../router/config/paths";
import { APP_URL, BASIC_APP_CREATE_URL, CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import { getAuthTokenFromLocalStorage } from "hooks/useAuthToken";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useClickOutside } from "hooks/useClickOutside";
import MobileHeaderMenu from "./MobileHeaderMenu";
import AnnouncementModal from "./AnnouncementModal";

export enum PageType {
  Explore = "/",
  Create = "/create",
  Pricing = "/pricing",
}

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [initTab, setInitTab] = React.useState<any>(location.pathname);
  const { isAuthorized, user, isCheckingToken } = useAppSelector((state) => state.common);
  const [showProductsSelect, setShowProductsSelect] = useState(false);
  const productsSelectRef = useRef(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useClickOutside(productsSelectRef, () => setShowProductsSelect(false));

  React.useEffect(() => {
    if (location.pathname !== initTab) {
      setInitTab(location.pathname);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (user.id !== "") {
      userService
        .getUserInfo({ uid: user?.id })
        .then((res: any) => dispatch(setUser({ id: res.user_info.id, email: res.user_info.email, name: res.user_info.name, username: res.user_info.username, work: res.user_info.work })));
    }
  }, [isAuthorized]);

  const toggleDropdown = () => {
    AnalyticsEvent("profile_tapped", "", "click");
    setIsOpen(!isOpen);
  };

  const HeaderItems = [
    {
      title: "Features",
      onClick: () => {
        AnalyticsEvent("header_features", "", "click");
        navigate(PATHS.FEATURES);

        return;
      },
    },
    {
      title: "Showcase",
      onClick: () => {
        AnalyticsEvent("header_showcase", "", "click");
        navigate(PATHS.SHOWCASE);

        return;
      },
    },
    {
      title: "Pricing",
      onClick: () => {
        AnalyticsEvent("header_pricing", "", "click");
        navigate(PATHS.PRICING);

        return;
      },
    },
    {
      title: "Ambassadors",
      onClick: () => {
        AnalyticsEvent("header_ambassadors", "", "click");
        navigate(PATHS.AMBASSADORS);

        return;
      },
    },
    {
      title: "About",
      onClick: () => {
        AnalyticsEvent("header_about", "", "click");
        navigate(PATHS.ABOUT);

        return;
      },
    },
  ];

  const renderLinks = () => {
    return (
      !isMobile && (
        <div className="flex gap-8 text-[17px] font-normal leading-normal text-grey">
          {HeaderItems.map((item, index) => (
            <div className="relative" key={`header-button_${index}`}>
              <a className={clsx("header-button", showProductsSelect ? "pointer-events-none" : "")} onClick={item.onClick}>
                {item.title}
              </a>
            </div>
          ))}
        </div>
      )
    );
  };

  const renderCreateButton = () => {
    return (
      !isMobile &&
      location.pathname !== PageType.Create && (
        <Button
          className="btn-primary"
          onClick={() => {
            AnalyticsEvent("create_button_header", "", "click");
            navigate(BASIC_APP_CREATE_URL + "?token=" + getAuthTokenFromLocalStorage() + "&client_id=" + useLocalStorage().getItem(CLIENT_ID_LOCAL_STORAGE_KEY));
          }}
        >
          Create
        </Button>
      )
    );
  };

  const Announcement = () => (
    <div
      className="cursor-pointer flex-center w-full p-2.5 gap-2.5 bg-green"
      onClick={() => {
        AnalyticsEvent("header_announcement", "", "click");
        dispatch(toggleAnnouncementModal());
      }}
    >
      <span className="text-h7-satoshi text-center !text-[15px]">Refabric is joining the LVMH Startup Acceleration Program at La Maison des Startups</span>
      <IconPlay className=" w-[14px] h-[14px] lg:w-4 lg:h-4 flex-shrink-0" />
    </div>
  );

  return (
    <div className={clsx("flex items-center sticky top-0 z-50 w-full flex-col  border-b border-grey-light")}>
      <Announcement />
      <AnnouncementModal />

      {isMobile && showMobileMenu && <MobileHeaderMenu show={showMobileMenu} headerItems={HeaderItems} onClose={() => setShowMobileMenu(false)} />}

      <div className="container-fluid-header flex h-full gap-7 items-center bg-white w-full justify-between py-3 lg:px-5 border-b border-grey-light lg:border-0">
        <div className="flex items-center gap-10">
          <LogoRefabric
            className="cursor-pointer"
            onClick={() => {
              AnalyticsEvent("header_refabric_logo", "", "click");
              navigate(PATHS.HOME);
            }}
          />
          {renderLinks()}
        </div>
        <div className="flex justify-end relative items-center gap-2.5 w-full lg:max-w-[300px]">
          {isCheckingToken ? (
            <></>
          ) : !isAuthorized ? (
            <>
              {!isMobile && (
                <a
                  href={APP_URL + "/login" + window.location.search}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full"
                  onClick={() => {
                    // AnalyticsEvent("header_signin", "", "click");
                  }}
                >
                  <Button className="btn-secondary btn-sm whitespace-nowrap w-full">Sign In</Button>
                </a>
              )}
              <Button
                className="btn-primary btn-sm whitespace-nowrap flex-shrink-0 w-full max-w-[144px]"
                onClick={() => {
                  AnalyticsEvent("header_tryforfree", "", "click");
                  navigate(PATHS.PRICING);
                }}
              >
                Try for Free
              </Button>
              {isMobile && <IconMenu className="text-black w-6 h-6 flex-shrink-0" onClick={() => setShowMobileMenu(true)} />}
            </>
          ) : (
            <>
              {renderCreateButton()}
              {user.username !== "" && (
                <Button
                  disabled={isOpen}
                  onClick={toggleDropdown}
                  className="flex-center text-h5 text-white py-2.5 px-4 rounded-full bg-white bg-opacity-10 border border-white border-opacity-10 uppercase"
                >
                  {user?.username?.slice(0, 1)}
                </Button>
              )}
              <div className="absolute top-[56px] right-0">{isOpen && <AvatarMenu setIsOpen={setIsOpen} />}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
